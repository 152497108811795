import { SESSION_TYPE } from "./enums";
import { Session } from "./types";

const textFormat = {
    text: "Text",
    fontFamily: "Lato",
    fontSize: 24,
    bold: false,
    italic: false,
    underline: false,
    fontCase: "none",
    color: "#000000",
    opacity: 100,
}

export function getProjectDataForSession (session?: Session) {
    const projectData: any = {};

    if (!session || !session.data) { return projectData; }

    const cta = session.data.headline && session.data.headline.length > 0 ? session.data.headline
        : session.data.cta && session.data.cta.length > 0 ? session.data.cta.toLowerCase().split("_").map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
            : "Buy now!";
    const offer = session.data.description && session.data.description.length > 0 ? session.data.description : "Huge discounts available!";

    projectData.creativeImage = session.data.creative_image;
    projectData.ctaText = {
        ...textFormat,
        text: cta,
    };
    projectData.offerText = {
        ...textFormat,
        text: offer,
    };

    return projectData;
}

export const isSessionRestricted = (session?: Session) => !session || session.type === SESSION_TYPE.EXTERNAL;
