import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useRoutes, useSearchParams } from 'react-router-dom';
import './App.css';
import { Loader } from './components/Loader';
import routes from './routes/routes';
import { fetchAssetReducer } from './store/asset/slice';
import { fetchUserReducer, updateSessionData } from './store/authentication/slice';
import { getProjectsReducer } from './store/project/slice';
import { getApi, getApiWithoutCatch } from './utils/fetchUtils';
import { PROJECT_TYPE, SESSION_TYPE } from './utils/enums';
import { Session } from './utils/types';

export const fallback = <div style={{
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  gap: 20,
  justifyContent: "center",
  alignItems: "center",
  color: "#848484",
}}>
  <h3>Loading...</h3>
  <Loader />
</div>;

const openPaths = ["/auth", "/expired", "/ai"];
const openExternalPaths = ["/billboard", "/unreal", "/stable-diffuision"]
const EXTERNAL_OPEN_PATHS_PROJECT_MAP: { [arg0: string]: string[] } = {
  [PROJECT_TYPE.BILLBOARD]: ["/billboard"],
  [PROJECT_TYPE.UNREAL]: ["/unreal"],
  [PROJECT_TYPE.STABLE_DIFFUSION]: ["/stable-diffsuion"],
}

function App() {
  const routesResult = useRoutes(routes);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionToken = searchParams.get("session");

  useEffect(() => {
    if (sessionToken) {
      window.localStorage.setItem("token", sessionToken);
      setSearchParams(undefined);
    }

    const isOpen = openPaths.reduce((prev, curr) => prev || window.location.pathname.startsWith(curr), false);
    if (!isOpen) {
      getApi("session")
        .then((session: Session) => {
          dispatch(updateSessionData(session));

          dispatch(fetchUserReducer());
          dispatch(fetchAssetReducer());
          dispatch(getProjectsReducer());

          if (session.type === SESSION_TYPE.EXTERNAL) {
            const projectType = session.data?.type;
            let allowedPaths = false;
            if (!projectType) {
              allowedPaths = openExternalPaths.reduce((prev, curr) => prev || window.location.pathname.startsWith(curr), false);
            } else {
              allowedPaths = EXTERNAL_OPEN_PATHS_PROJECT_MAP[projectType].reduce((prev, curr) => prev || window.location.pathname.startsWith(curr), false);
            }
            if (!allowedPaths) {
              navigate("/");
            }
          }
        })
    }
    else {
      getApiWithoutCatch("session")
        .then((session: Session) => {
          dispatch(updateSessionData(session));
          dispatch(fetchUserReducer());
          dispatch(fetchAssetReducer());
          dispatch(getProjectsReducer());
        })
        .catch((err) => {});
    }
  }, []);

  return (
    <div className="App">
      <Suspense fallback={fallback}>{routesResult}</Suspense>
    </div>
  );
}

export default App;
