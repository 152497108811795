import { lazy } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import Navbar from "../components/Navbar/Navbar";
import styles from "../App.module.css";
const Expired = lazy(() => import("../containers/start/Expired"));
const BillBoard = lazy(() => import("../containers/billboard/Billboard"));
const Library = lazy(() => import("../containers/library/Library"));
const MLibrary = lazy(() => import("../containers/mlibrary/MLibrary"));
const Brands = lazy(() => import("../containers/brands/Brands"));
const Project = lazy(() => import("../containers/project/Project"));
const Animation = lazy(() => import("../containers/unreal/Animation"));
const Home = lazy(() => import("../containers/home/Home"));
const Profile = lazy(() => import("../containers/profile/Profile"));
const StartBuildingAds = lazy(() => import("../containers/startBuildingAds"));
const AuthRouter = lazy(() => import("../containers/auth"));
const Learn = lazy(() => import("../containers/learn"));
const StableDiffusion = lazy(() => import("../containers/stableDiffusion/StableDiffusion"));
const AIRouter = lazy(() => import("../containers/ai"));


export const routes = [
  /* routes with both header and navbar */
  {
    path: "/",
    element: <>
      <Header />
      <div className={styles.mainback}>
        <Navbar />
        <div className={styles.content__right_container}>
          <Outlet />
        </div>
      </div>
    </>,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/library",
        element: <Library />
      },
      {
        path: "/mlibrary",
        element: <MLibrary />
      },
      {
        path: "/brandkit",
        element: <Brands />
      },
      {
        path: "/project",
        element: <Project />
      },
      {
        path: "/learn/:slug?",
        element: <Learn />,
      },
      {
        path: "/ai/*",
        element: <AIRouter />,
      },
    ],
  },
  /* routes with only header */
  {
    path: "/",
    element: <>
      <Header />
      <div className={styles.mainback}>
          <Outlet />
      </div>
    </>,
    children: [
      {
        path: "/account/*",
        element: <Profile />,
      },
    ],
  },
  /* routes with customized/no header and no navbar*/
  {
    path: "/billboard/:projectId",
    element: <BillBoard />
  },
  {
    path: "/animation/:projectId/:sceneName",
    element: <Animation />,
  },
  {
    path: "/stable-diffusion/:projectId",
    element: <StableDiffusion />
  },
  {
    path: "/auth/*",
    element: <AuthRouter />
  },
  {
    path: "/start-building-ads",
    element: <StartBuildingAds />
  },
  {
    path: "/expired",
    element: <Expired />
  },
]

export default routes;
