import axios, { AxiosError, AxiosResponse } from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
const apiUrl = `${BASE_URL}api/`;

const API_KEY = process.env.REACT_APP_BACKEND_API_KEY;

function successHandler (response: AxiosResponse) {
  const json = response.data;
  if (json.success) {
    return json.data;
  } else {
    console.error("API error", json);
  }
}

function errorHandler (err: AxiosError | any) {
  if (err.response?.status === 401) {
    window.location.assign("/expired");
  } else {
    console.error("API error", err.response?.data);
    throw err.response?.data?.error ?? err.response?.data ?? err.response ?? err;
  }
}

export function getApi (url: string, headers?: any) {
  url = apiUrl + url;
  const token = window.localStorage.getItem("token");
  if (!headers) {
    headers = { Authorization : `Bearer ${token}` };
  }

  return axios.get(url, { headers })
  .then(successHandler)
  .catch(errorHandler);
};

export function getApiWithoutCatch (url: string, headers?: any) {
  url = apiUrl + url;
  const token = window.localStorage.getItem("token");
  if (!headers) {
    headers = { Authorization : `Bearer ${token}` };
  }

  return axios.get(url, { headers })
  .then(successHandler)
};

export function postApi (url: string, payload?: any, useApiKey: boolean = false) {
  url = apiUrl + url;

  const headers: any = { "Content-Type": "application/json" };

  if (useApiKey) {
    headers["x-api-key"] = API_KEY;
  } else {
    const token = window.localStorage.getItem("token");
    headers["Authorization"] = `Bearer ${token}`;
  }

  return axios.post(url, payload, { headers })
  .then(successHandler)
  .catch(errorHandler);
};

export function putApi (url: string, payload: any) {
  url = apiUrl + url;
  const token = window.localStorage.getItem("token");
  const headers = { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` };

  return axios.put(url, payload, { headers })
  .then(successHandler)
  .catch(errorHandler);
};

export function deleteApi (url: string) {
  url = apiUrl + url;
  const token = window.localStorage.getItem("token");
  const headers = { "Content-Type": "application/json", Authorization: `Bearer ${token}` };

  return axios.delete(url, { headers })
  .then(successHandler)
  .catch(errorHandler);
}
