import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'

import saga from './saga';
import assetReducer, { uploadAssetReducer } from './store/asset/slice';
import authenticationReducer from './store/authentication/slice';
import projectReducer from './store/project/slice'
import brandReducer from './store/brand/slice'
import { USER_LOGOUT } from './utils/enums';


const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const appReducer = combineReducers({
  assets: assetReducer, 
  authentication: authenticationReducer,
  project: projectReducer,
  brand: brandReducer
})

const rootReducer = (state:any, action:any) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [uploadAssetReducer.type],
      }
    }).concat(middleware),
})

sagaMiddleware.run(saga);
