import {call, all, spawn} from 'redux-saga/effects'
import assetSaga from "./store/asset/saga";
import authenticationSaga from './store/authentication/saga';
import projectSaga from './store/project/saga';
import brandSaga from './store/brand/saga';

function* rootSaga() {
  const sagas = [
    assetSaga,
    authenticationSaga, 
    projectSaga,
    brandSaga,
  ];

  yield all(sagas.map(saga =>
    spawn(function* () {
      try {
        yield call(saga);
      } catch (e) {
        console.log(e);
      }
    }))
  );
}

export default rootSaga;
