import { call, put, takeLatest, select } from 'redux-saga/effects';
import { postApi, getApi, deleteApi } from '../../utils/fetchUtils';
import {
  getProjectsReducer, updateProjects,
  updateProjectByIdReducer, updateProjectLocalReducer, updateProjectsById,
  getProjectReducer,
  deleteProjectReducer,
  deleteProjectAction
} from './slice';
import { ProjectType } from '../../utils/types';

export const createProject = (payload: any) => postApi("project", payload);

export function* getProjects() {
  const projects: ProjectType[] = yield call(getApi, "project");
  yield put(updateProjects(projects));
}

function* getProject(action: { type: string, payload: { projectId: string } }) {
  const project: ProjectType = yield call(getApi, `project/${action.payload.projectId}`);
  yield put(updateProjectsById(project));
}

function* updateProjectById(action: { type: string, payload: any }) {
  const projectId = action.payload.projectId;
  delete action.payload.projectId;
  const project: ProjectType = yield call(postApi, `project/${projectId}`, action.payload);
  yield put(updateProjectsById(project));
}

function* updateProjectLocal(action: { type: string, payload: Project }) {
  yield put(updateProjectsById(action.payload));
}

function* deleteProject(action: { type: string, payload: { projectId: string } }) {
  const projectId = action.payload.projectId;
  yield call(deleteApi, `project/${projectId}`);
  yield put(deleteProjectAction({ projectId }));
}

export default function* projectSaga() {
  yield takeLatest(getProjectsReducer.type, getProjects);
  yield takeLatest(getProjectReducer.type, getProject);
  yield takeLatest(updateProjectByIdReducer.type, updateProjectById);
  yield takeLatest(updateProjectLocalReducer.type, updateProjectLocal);
  yield takeLatest(deleteProjectReducer.type, deleteProject);
}
