import { call, put, takeLatest } from 'redux-saga/effects';
import { getApi, postApi } from '../../utils/fetchUtils';
import { fetchUserReducer, updateUserReducer, updateData} from './slice';
import { CurrUser } from '../../utils/types';

export function* fetchUser() {
  const data: CurrUser = yield call(getApi, "user");
  yield put(updateData(data));
}

export function* updateUser(action: {type: string, payload: {first_name: string, last_name: string}}) {
  const data: CurrUser  = yield call(postApi, "user", action.payload);
  yield put(updateData(data));
}

export default function* authenticationSaga() {
  yield takeLatest(fetchUserReducer.type, fetchUser)
  yield takeLatest(updateUserReducer.type, updateUser)
}
