import { createSlice } from "@reduxjs/toolkit";
import { ProjectType } from "../../utils/types";

const initialState = {
  projects: {} as { [id: string]: ProjectType },
}

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    getProjectsReducer() { },
    getProjectReducer() { },
    updateProjectByIdReducer() { },
    updateProjectLocalReducer() { },
    deleteProjectReducer() { },
    updateProjects: (state, action) => {
      action.payload.forEach((project: ProjectType) => {
        state.projects[project.id] = project;
      })
    },
    deleteProjectAction: (state, action) => {
      delete state.projects[action.payload.projectId];
    },
    updateProjectsById: (state, action) => {
      state.projects[action.payload.id] = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  getProjectsReducer, 
  getProjectReducer, 
  updateProjects,
  updateProjectsById, updateProjectByIdReducer, updateProjectLocalReducer,
  deleteProjectReducer, deleteProjectAction
} = projectSlice.actions

export default projectSlice.reducer;
