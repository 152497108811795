import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteApi, getApi, postApi } from '../../utils/fetchUtils';
import {
	fetchBrandUsersReducer, fetchInvitedUsersReducer,
	updateUserRoleReducer, updateUserRole,
	updateBrandUsers, updateInvitedUsers,
	removeUserReducer, cancelInviteReducer,
	removeUserAction, cancelInviteAction
} from './slice';
import { Invite, User } from '../../utils/types';
import { USER_ROLE } from '../../utils/enums';

export function* fetchBrandUsers() {
	const users: User[] = yield call(getApi, "user/all");
	yield put(updateBrandUsers(users));
}

export function* fetchInvitedUsers() {
	const data: Invite[] = yield call(getApi, "invite/users");
	yield put(updateInvitedUsers(data));
}

export function* updateRole(action: {type: string, payload: { user: User, role: USER_ROLE }}) {
	yield call(postApi, `user/${action.payload.user.id}`, { role: action.payload.role });
	yield put(updateUserRole(action.payload));
}

export function* removeUser(action: {type: string, payload: {userId: string}}) {
	yield call(deleteApi, `user/${action.payload.userId}`);
	yield put(removeUserAction(action.payload));
}

export function* cancelInvite(action: {type: string, payload: {inviteId: string}}) {
	yield call(deleteApi, `invite/${action.payload.inviteId}`);
	yield put(cancelInviteAction(action.payload));
}

export default function* brandSaga() {
	yield takeLatest(fetchBrandUsersReducer.type, fetchBrandUsers)
	yield takeLatest(fetchInvitedUsersReducer.type, fetchInvitedUsers)
	yield takeLatest(updateUserRoleReducer.type, updateRole)
	yield takeLatest(removeUserReducer.type, removeUser)
	yield takeLatest(cancelInviteReducer.type, cancelInvite)
}
