import ImagePixisLogo from "../../assets/logo.png";
import './header.css';
import { Box, Avatar, MenuItem, ListItemIcon, Fade, IconButton, Menu, ThemeProvider, Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Groups, Logout, Person } from '@mui/icons-material';
import { mainTheme, theme } from '../../utils/theme';
import { useSelector } from 'react-redux';
import { User } from '../../utils/types';
import { isSessionRestricted } from "../../utils/session";

type ActionButton = {
	icon: string,
	name: string,
	onClick: () => void,
}

type HeaderProps = {
	displayName?: string,
	showButtons?: Boolean,
	buttons?: ActionButton[],
}

function Header(props: HeaderProps) {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);

	const restricted = useSelector((store: any) => isSessionRestricted(store.authentication.session));

	const open = Boolean(anchorEl);
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleLogout = () => {
		setAnchorEl(null);
		navigate("/auth/logout");
	}
	const user: User = useSelector((store: any) => store.authentication.user);

	let userName = "";
	if (user && user.firstName) {
		userName = user.firstName.charAt(0).toUpperCase();
	}
	if (user && user.lastName) {
		userName += user.lastName.charAt(0).toUpperCase();
	}

	return (
		<header>
			<ThemeProvider theme={mainTheme}>
				<ThemeProvider theme={theme}>
					<Menu
						id="fade-menu"
						MenuListProps={{
							"aria-labelledby": "fade-button",
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						TransitionComponent={Fade}
					>
						<MenuItem onClick={() => { navigate("/account/profile"); setAnchorEl(null); }}>
							<ListItemIcon>
								<Person fontSize="small" />
							</ListItemIcon>
							Profile
						</MenuItem>
						<MenuItem onClick={() => { navigate("/account/team"); setAnchorEl(null); }}>
							<ListItemIcon>
								<Groups fontSize="small" />
							</ListItemIcon>
							Manage Team
						</MenuItem>
						<MenuItem onClick={handleLogout}>
							<ListItemIcon>
								<Logout fontSize="small" />
							</ListItemIcon>
							Logout
						</MenuItem>
					</Menu>
				</ThemeProvider>
			</ThemeProvider>
			<Box className="header_main" style={{ height: "60px" }}>
				<Box className="header_logo">
					<img className="header__logo_image" src={ImagePixisLogo} alt="" onClick={() => navigate("/")} />
					{props.displayName && <Box className="dd-button">{props.displayName}</Box>}
				</Box>
				<Box className="header__right">
					{props.showButtons && <Box className="header_manu">
						{props.buttons?.map((button, index) =>
							<a href="#" key={index} className="rendurbut" onClick={button.onClick}>
								<Icon sx={{ mr: 1 }}>{button.icon}</Icon>
								{button.name}
							</a>
						)}
					</Box>}
					{!restricted && userName && <IconButton
						className="header__profile"
						onClick={handleClick}
						size="small"
						sx={{ ml: 2 }}
						aria-controls={open ? "account-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
					>
						<Avatar sx={{ width: 40, height: 40, background: "#396EEA" }}>{userName}</Avatar>
					</IconButton>}
				</Box>
			</Box>
		</header>
	);
}

export default Header;
