import { createSlice } from "@reduxjs/toolkit";
import { Invite, User } from "../../utils/types";

const initialState = {
	brandUsers: {} as { [id: string]: User },
	invitedUsers: [] as Invite[]
}

export const brandSlice = createSlice({
	name: "brand",
	initialState,
	reducers: {
		fetchBrandUsersReducer() { },
		fetchInvitedUsersReducer() { },
		updateUserRoleReducer() { },
		removeUserReducer() { },
		cancelInviteReducer() { },
		updateBrandUsers: (state, action) => {
			action.payload.forEach((user: any) => {
				state.brandUsers[user.id] = user;
			})
		},
		updateInvitedUsers: (state, action) => {
			state.invitedUsers = action.payload;
		},
		updateUserRole: (state, action) => {
			state.brandUsers[action.payload.user.id] = { ...action.payload.user, role: action.payload.role };
		},
		removeUserAction: (state, action) => {
			delete state.brandUsers[action.payload.userId]
		},
		cancelInviteAction: (state, action) => {
			state.invitedUsers = state.invitedUsers.filter(invite => invite.id !== action.payload.inviteId);
		},
		
	},
})

// Action creators are generated for each case reducer function
export const {
	fetchBrandUsersReducer, updateBrandUsers,
	fetchInvitedUsersReducer, updateInvitedUsers,
	updateUserRoleReducer, updateUserRole,
	removeUserReducer, cancelInviteReducer,
	removeUserAction, cancelInviteAction
} = brandSlice.actions

export default brandSlice.reducer;