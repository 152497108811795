export enum SESSION_TYPE {
  "USER" = "USER",
  "EXTERNAL" = "EXTERNAL",
};

export enum SCOPE {
  "BRANDKIT" = "BRANDKIT",
  "BRAND" = "BRAND",
  "PRIVATE" = "PRIVATE",
  "GLOBAL" = "GLOBAL",
};

export enum ASSET_TYPE {
  "VIDEO" = "VIDEO",
  "IMAGE" = "IMAGE",
  "COLOR" = "COLOR",
  "FONT" = "FONT",
  "GREEN_SCREEN" = "GREEN_SCREEN",
  "3D_PRODUCT" = "3D_PRODUCT",
  "3D_MAP" = "3D_MAP",
  "3D_ANIMATION" = "3D_ANIMATION",

  // frontend only
  "TEXT" = "TEXT",
  "LIBRARY" = "LIBRARY",
  "BRAND" = "BRAND",
  "3D_SCENE" = "3D_SCENE",
  "LOGO" = "LOGO",
  "TEMPLATES" = "TEMPLATES"
};

export enum PROJECT_TYPE {
  "BILLBOARD" = "BILLBOARD",
  "UNREAL" = "UNREAL",
  "UNREAL_SCENE" = "UNREAL_SCENE",
  "VIDEO" = "VIDEO",
  "STABLE_DIFFUSION" = "STABLE_DIFFUSION",
}

export enum TASK_STATUS {
  "CREATING" = "CREATING",
  "RUNNING" = "RUNNING",
  "COMPLETED" = "COMPLETED",
  "ERROR" = "ERROR",
};

export enum USER_ROLE {
  "ADMIN" = "ADMIN",
  "EDITOR" = "EDITOR",
  "VIEWER" = "VIEWER",
}

export enum MODAL_MODE {
  "EDITING" = "EDITING",
  "DISPLAY" = "DISPLAY",
  "UPLOAD" = "UPLOAD",
}

export enum INVITE_STATUSES {
  "PENDING" = "PENDING",
  "EXPIRED" = "EXPIRED",
  "SIGNUP" = "SIGNUP",
  "VERIFIED" = "VERIFIED",
  "COMPLETED" = "COMPLETED",
}

export const USER_LOGOUT = "USER_LOGOUT"