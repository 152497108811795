import { createSlice } from "@reduxjs/toolkit";
import { Brand, Session, User } from "../../utils/types";
import { USER_ROLE } from "../../utils/enums";

const initialState = {
  user: null as User | null,
  role: null as USER_ROLE | null,
  brand: null as Brand | null,
  session: null as Session | null
}

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    fetchUserReducer() {},
    updateUserReducer() {},
    updateData: (state, action) => {
      state.role = action.payload.role;
      state.brand = action.payload.brand;
      delete action.payload.brand;
      state.user = action.payload;
    },
    updateSessionData: (state, action) => {
      state.session = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateData, updateUserReducer, fetchUserReducer, updateSessionData } = authenticationSlice.actions

export default authenticationSlice.reducer;
