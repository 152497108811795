import { call, put, takeLatest } from 'redux-saga/effects';
import { ASSET_TYPE, SCOPE } from '../../utils/enums';
import { getApi, putApi ,deleteApi, postApi } from '../../utils/fetchUtils';
import { Asset } from '../../utils/types';
import {
    updateAllAssets, fetchAssetReducer, 
    updateBrandAssets,
    updateImageAssets,
    updateVideoAssets,
    updateGreenScreenAssets,
    updateMapAssets, 
    updateFontAssets, 
    updateProductAssets, 
    updateAnimationAssets, 
    uploadAssetReducer,
    updateAssetReducer,
    deleteAssetReducer
} from './slice';

export function* fetchAllAssets() {
    const allAssets: Asset[] = yield call(getApi, "asset");
    yield put(updateAllAssets(allAssets));
    yield put(updateBrandAssets(allAssets.filter(asset => asset.scope === SCOPE.BRANDKIT)));
    yield put(updateImageAssets(allAssets.filter(asset => asset.type === ASSET_TYPE.IMAGE)));
    yield put(updateVideoAssets(allAssets.filter(asset => asset.type === ASSET_TYPE.VIDEO)));
    yield put(updateGreenScreenAssets(allAssets.filter(asset => asset.type === ASSET_TYPE.GREEN_SCREEN)));
    yield put(updateMapAssets(allAssets.filter(asset => asset.type === ASSET_TYPE["3D_MAP"])));
    yield put(updateFontAssets(allAssets.filter(asset => asset.type === ASSET_TYPE.FONT)));
    yield put(updateProductAssets(allAssets.filter(asset => asset.type === ASSET_TYPE["3D_PRODUCT"])));
    yield put(updateAnimationAssets(allAssets.filter(asset => asset.type === ASSET_TYPE["3D_ANIMATION"])));
}

function* updateAsset(action: { type: string, payload: any }){
    yield call(postApi, `asset/${action.payload.id}`, action.payload);
    yield put({ type: fetchAssetReducer.type })
}

function* deleteAsset(action: { type: string, payload: { id: string } }){
    yield call(deleteApi, `asset/${action.payload.id}`);
    yield put({ type: fetchAssetReducer.type })
}

function* uploadAssets(action: { type: string, payload: { file: File, name: string, type: string, mimeType: string, scope: string, tags: string[] } }){
    const formData = new FormData();
    formData.append("file", action.payload.file);
    formData.append("mime_type", action.payload.mimeType)
    formData.append("name", action.payload.name);
    formData.append("type", action.payload.type);
    formData.append("scope", action.payload.scope);

    for (const tag of action.payload.tags) {
        formData.append("tags[]", tag);
    }

    yield call(putApi, "asset", formData);
    yield put({ type: fetchAssetReducer.type });
}

export default function* assetSaga() {
    yield takeLatest(fetchAssetReducer.type, fetchAllAssets);
    yield takeLatest(uploadAssetReducer.type, uploadAssets);
    yield takeLatest(deleteAssetReducer.type, deleteAsset);
    yield takeLatest(updateAssetReducer.type, updateAsset);
}
