import { createSlice } from "@reduxjs/toolkit";
import { Asset } from "../../utils/types";

const initialState = {
  allAssets: {} as { [id: string]: Asset },
  brandAssets: [] as Asset[],
  imageAssets: [] as Asset[],
  videoAssets: [] as Asset[],
  mapAssets: [] as Asset[],
  fontAssets: [] as Asset[],
  productAssets: [] as Asset[],
  animationAssets: [] as Asset[],
  greenScreenAssets: [] as Asset[],
}

export const assetSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    fetchAssetReducer() { },
    uploadAssetReducer() { },
    deleteAssetReducer() { },
    updateAssetReducer() { },
    updateAllAssets: (state, action) => {
      state.allAssets = {};
      action.payload.forEach((asset: Asset) => {
        state.allAssets[asset.id] = asset;
      })
    },
    updateBrandAssets: (state, action) => {
      state.brandAssets = action.payload;
    },
    updateImageAssets: (state, action) => {
      state.imageAssets = action.payload
    },
    updateVideoAssets: (state, action) => {
      state.videoAssets = action.payload
    },
    updateFontAssets: (state, action) => {
      state.fontAssets = action.payload
    },
    updateGreenScreenAssets: (state, action) => {
      state.greenScreenAssets = action.payload
    },
    updateMapAssets: (state, action) => {
      state.mapAssets = action.payload
    },
    updateProductAssets: (state, action) => {
      state.productAssets = action.payload
    },
    updateAnimationAssets: (state, action) => {
      state.animationAssets = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateAllAssets, fetchAssetReducer, 
  updateBrandAssets, 
  updateImageAssets,
  updateVideoAssets,
  updateGreenScreenAssets,
  updateMapAssets, 
  updateFontAssets, 
  updateProductAssets, 
  updateAnimationAssets, 
  uploadAssetReducer,
  deleteAssetReducer,
  updateAssetReducer,
} = assetSlice.actions

export default assetSlice.reducer;
