import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#fff',
      main: 'rgb(23, 105, 170)',
      dark: '#000',
      contrastText: "#fff"
    },
    secondary: {
      main: '#f44336',
    },
    background: {
      paper: "#fff"
    },
    paper: {
      flexGrow: 1,
      color: '#000'
    },
    warningGradient: {
      main: 'linear-gradient(270deg, rgba(221, 194, 0, 0.8) 6.11%, rgba(255, 121, 0, 0.8) 101.53%)',
    },
    deactivated: {
      main: "rgba(226, 235, 244, 0.6)"
    }
  },
  components: {
    Mui: {
      styleOverrides: {
        "&Mui-selected": {
          color: "#fff"
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          background: "#111314",
          // padding: "10px",
        }
      }
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          height: "inherit"
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: "#181A22",
          boxShadow: "0px 0px 2px rgba(255, 255, 255, 0.25)",
          borderRadius: "16px",
          backdropFilter: 'blur(94.1168px)',
          boxSizing: 'border-box',
          width: '307px',
          height: '300px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          display: 'flex-inline',
          padding: '10px 16px 10px 16px',
          justifyContent: 'space-between',
          bottom: '5px',
          position: 'fixed'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: 'linear-gradient(115.11deg, #3E69FA 16.81%, #2DAEF5 78.52%)',
          borderRadius: '5px 5px 0px 0px',
          width: '51px',
          height: '5px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        primary: {
          background: '#3E69FA',
          borderRadius: '8px',
          color: "#FFFFFF",
          width: '129.5px',
          height: '28px',
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        },
        secondaryGradient: {
          color: "#FFFFFF",
          background: 'linear-gradient(123.27deg, #10DACB 7.07%, #1863F4 83.27%)', width: '129.5px', height: '28px', borderRadius: '8px',
        },
        warningGradient: {
          color: "#FFFFFF",
          background: 'linear-gradient(270deg, rgba(221, 194, 0, 0.8) 6.11%, rgba(255, 121, 0, 0.8) 101.53%)', width: '129.5px', height: '28px', borderRadius: '8px'
        },
        primaryGradient: {
          background: "linear-gradient(115.11deg, #3E69FA 16.81%, rgba(45, 174, 245, 0.58) 78.52%)",
          borderRadius: '15px',
          color: "#FFFFFF",
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        Typography: {
          color: '#000'
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: "#1F262E"
        }
      }

    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "auto"
        }
      }
    }
  },
  typography: {
    h4: {
      fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 700, fontSize: '36px', lineHeight: '35px'
    },
    h5: {
      height: '22px', mixBlendMode: 'normal', fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 500, fontSize: '17.927px'
    },
    subtitle2: {
      height: '16px', color: '#AFB7D6', fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 300, fontSize: '13.4453px', lineHeight: '16px'
    },
    h3: {
      // width: "146px",
      height: "35px",
      left: "32px",
      top: "32px",
      fontFamily: 'Inter',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "36px",
      lineHeight: "35px",
      mixBlendMode: "normal",
    }

  }
});

export const mainTheme = createTheme({
  mode: 'dark',
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          background: "#111314"
        }
      }
    },
  }
})